//
// index.tsx - Org. Home page
//

import CreateProject from "@components/Projects/CreateProject";
import { useGetUserProjects } from "@custom-hooks/Projects";
import { useCheckAuth } from "@custom-hooks/useCheckAuth";
import { useGetUserPlanInfo } from "@custom-hooks/User";
import { Project, Projects } from "@data-types/projects-types";
import OpenDialog from "@generic-components/OpenDialog";
import { ApplicationLayout } from "@layouts/ApplicationLayout/ApplicationLayout";
import { UpgradeDialog } from "@layouts/ApplicationLayout/UpgradeDialog";
import { UpgradingInfraBanner } from "@layouts/ApplicationLayout/UpgradingInfraBanner";
import BoltIcon from "@layouts/svg-icon/bolt-icon.svg";
import RocketBadge from "@layouts/svg-illustration/rocket-badge.svg";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { CreateProjectCard } from "@tw-components/OrgHome/CreateProjectCard";
import { ProjectCard } from "@tw-components/OrgHome/ProjectCard";
import { Button } from "@tw-components/ui/button";
import clsx from "clsx";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import { MutableRefObject, useEffect, useRef, useState } from "react";

/**
 * Default Component
 *
 * This component displays a list of user projects with options to filter, create, and manage projects.
 * It shows an infrastructure upgrade banner if needed, a project creation dialog, an upgrade prompt,
 * and filtered projects based on a search query.
 *
 * @returns {JSX.Element} The rendered Default component.
 */
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

function Default() {
  // Check authentication status
  useCheckAuth(true, false);

  // State to manage the visibility of the upgrade dialog
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

  // Toggle the upgrade dialog visibility
  const handleShowUpgradeDialog = () => {
    setShowUpgradeDialog((prevState) => !prevState);
  };

  // Set up router and createProjectRef reference for modal control
  const router = useRouter();
  const { query } = router;
  const createProjectRef = useRef<MutableRefObject<any | null>>(null);

  // Fetch user projects and plan information
  const {
    data: projects,
    hasData: availableProjects,
    showLoader,
    emptyData: emptyProjects,
    isLoading: loadingProjects,
  } = useGetUserProjects();

  const { data: planInfo, isLoading: loadingPlanInfo } = useGetUserPlanInfo();

  // Calculate project limits and determine if project creation is enabled
  const totalProjects = planInfo?.projects ?? 0;
  const createdProjects = availableProjects ? (projects as Projects).length : 0;
  const enableCreateProject = createdProjects < totalProjects;

  /**
   * Filters projects based on the provided search query.
   * @param {Projects} items - List of user projects to filter.
   * @param {string} query - The search query for filtering.
   * @returns {Projects} The filtered list of projects.
   */
  function searchItems(items: Projects, query: string): Projects {
    if (!query) return items;
    const lowerCaseQuery = query.toLowerCase();
    return items.filter((item: Project) => {
      const lowerCaseName = item.name.toLowerCase();
      const lowerCaseDescription = item.description.toLowerCase();
      const lowerCaseId = item.id.toLowerCase();
      return (
        lowerCaseName.includes(lowerCaseQuery) ||
        lowerCaseDescription.includes(lowerCaseQuery) ||
        lowerCaseId.includes(lowerCaseQuery)
      );
    });
  }

  // Set up state and handlers for project filtering
  const [triggerFiltering, setTriggerFiltering] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState<Projects>([]);
  const [searchProjectsQuery, setSearchProjectsQuery] = useState<string>(
    (query.q as string) || ""
  );

  // Debounced handler to trigger filtering
  const handleTriggerFilter = debounce(() => {
    setTriggerFiltering((prev) => !prev);
  }, 150);

  // Update search query state and trigger filtering
  const handleUpdateSearchProjectsQuery = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchProjectsQuery(event.target.value);
    handleTriggerFilter();
  };

  // Update filtered projects and URL based on search query
  useEffect(() => {
    const currentPath = router.asPath.split("?")[0];
    const newUrl = searchProjectsQuery
      ? `${currentPath}?q=${searchProjectsQuery}`
      : currentPath;

    if (availableProjects) {
      const results = searchProjectsQuery
        ? searchItems(projects as Projects, searchProjectsQuery)
        : (projects as Projects);
      setFilteredProjects(results);
    } else {
      setFilteredProjects([]);
    }

    router.push(newUrl, undefined, { shallow: true });
  }, [availableProjects, projects, triggerFiltering, searchProjectsQuery]);

  // Combine loading states for projects and plan info
  const loadingInformation = loadingProjects || loadingPlanInfo;

  // Render component UI
  return (
    <>
      {/* Display search bar if plan is SQLite Cloud Dev */}
      {availableProjects && planInfo?.plan === "SQLite Cloud Dev" && (
        <div className="tw-relative tw-mx-auto tw-mb-4 tw-grid tw-w-full tw-max-w-[109rem]">
          <form
            style={{ width: "100%" }}
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <TextField
              value={searchProjectsQuery}
              onChange={handleUpdateSearchProjectsQuery}
              className="subvariant-modal-form"
              type="search"
              fullWidth
              placeholder="Search Projects"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
          </form>
        </div>
      )}

      <div className="tw-relative tw-mx-auto tw-grid tw-w-full tw-max-w-[109rem] tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-4 3xl:tw-grid-cols-9">
        {/* Infrastructure Upgrade Banner */}
        {UPGRADING_INFRA?.toLowerCase() === "true" && (
          <div
            className={clsx(
              "tw-col-span-1 sm:tw-col-span-2 3xl:tw-col-span-3",
              "tw-rounded-3xl tw-border tw-p-5",
              "tw-bg-semantics-informational10-light dark:tw-bg-semantics-informational10-dark",
              "tw-dark:tw-border-interface-divider-dark tw-border-interface-divider-light"
            )}
          >
            <UpgradingInfraBanner />
          </div>
        )}

        {/* Show loading skeleton if data is loading */}
        {loadingInformation ? (
          <div
            className={clsx(
              "tw-col-span-1 tw-min-h-48 sm:tw-col-span-2 3xl:tw-col-span-3",
              "tw-rounded-3xl",
              "tw-bg-interface-gray-light dark:tw-bg-interface-gray-dark",
              "tw-animate-pulse"
            )}
          ></div>
        ) : (
          <>
            {/* Create Project Card - shows upgrade dialog if creation is disabled */}
            <CreateProjectCard
              onClick={() => {
                if (enableCreateProject) {
                  // Open create project dialog
                  // @ts-expect-error OpenDialog method not typed for TypeScript
                  createProjectRef.current?.open();
                } else {
                  handleShowUpgradeDialog();
                }
              }}
              width="tw-col-span-1 sm:tw-col-span-2 3xl:tw-col-span-3"
            />

            {/* Display filtered projects */}
            {filteredProjects?.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                width="tw-col-span-1 sm:tw-col-span-2 3xl:tw-col-span-3"
              />
            ))}
          </>
        )}
      </div>
      <div className="tw-mb-4 tw-flex-1"></div>
      {/* Upgrade prompt for Playground plan */}
      {planInfo?.plan === "Playground" && (
        <div className="tw-dark:tw-bg-brandBlues-pale-dark tw-mx-auto tw-w-full tw-max-w-[109rem] tw-rounded-2xl tw-bg-brandBlues-pale-light tw-p-6">
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <div className="tw-flex tw-flex-col tw-gap-y-2">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-3">
                <RocketBadge />
                <div className="tw-dark:tw-text-brandBlues-brandDark-dark tw-text-h5-mob tw-text-brandBlues-brandDark-light sm:tw-text-h5-desk">
                  Upgrade to SQLite Cloud Pro
                </div>
              </div>
              <div className="tw-dark:tw-text-text-subTitle-dark tw-text-14px-regular tw-text-text-subTitle-light">
                Manage team members, create more projects, and get more storage
                with SQLite Cloud Pro.
              </div>
            </div>
            <Button
              onClick={handleShowUpgradeDialog}
              variant="primary"
              icon={<BoltIcon />}
              label="Upgrade To Pro"
            />
          </div>
        </div>
      )}
      {/* Upgrade Dialog Box */}
      <UpgradeDialog
        isOpen={showUpgradeDialog}
        setIsOpen={setShowUpgradeDialog}
      />
      {/* Create Project Dialog */}
      {/* @ts-expect-error OpenDialog method not typed for TypeScript */}
      <OpenDialog ref={createProjectRef} style="none" size="small-modal">
        {/* @ts-expect-error OpenDialog method not typed for TypeScript */}
        <CreateProject />
      </OpenDialog>
    </>
  );
}

// Specify layout for Default component
Default.getLayout = function getLayout(page: React.ReactNode) {
  return <ApplicationLayout>{page}</ApplicationLayout>;
};

export default Default;
